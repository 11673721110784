import React from 'react';

import classes from './Description.module.css';

const Description = (props) => {
    return (
        <React.Fragment>
            <div className={classes.headTitle}>
                The Dog Pound
            </div>
            <div className={classes.mainText}>
                We’ve been chewed up, spit out, and put behind bars. They’ve called us mutt, stray, and even tried to take our balls. In the pound, the only way to make it is to fight. Winning fights means gaining respect, love, and prizes. At the end of the day though, there can only be one alpha and we are all gunning for that spot. Pound dogs can now be locked up (in your wallet) for 0.055 eth each.
            </div>
        </React.Fragment>
    )
};

export default Description;


