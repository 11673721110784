import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";

// window.ethereum.request({ method: "eth_requestAccounts" });



const web3 = new Web3(window.ethereum);

const providerMobile = new WalletConnectProvider({
  infuraId: "bf486b18eb6d4692adaacdcdf3072e2b",
  qrcodeModalOptions: {
      mobileLinks: [
        "metamask"
      ],
    },
});

const web3Mobile = new Web3(providerMobile);



export {
    web3,
    web3Mobile,
    providerMobile
  };