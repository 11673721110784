import React from 'react';
import { isMobile } from "react-device-detect";



import classes from './HeaderMetaMaskButton.module.css';
import CircleIcon from './circleIcon';



const HeaderMetaMaskButton = props => {
    return (
        <React.Fragment>
            {isMobile && !props.showConnectButton ?
                <button className={`${classes.button} ${classes.clickable}`} onClick={props.onDisconnectClick}>
                    <span className={classes.icon}>
                        <CircleIcon showConnectButton={props.showConnectButton} />
                    </span>
                    {props.showConnectButton ? 
                        <span>
                            <span className={classes.headerText1}>Connect</span>
                        </span> :
                        <span className={classes.headerText}>Disconnect</span> 
                    }
                </button> :
                    <button className={`${classes.button} ${props.showConnectButton ? classes.clickable : ''}`} onClick={props.onConnectClick}>
                        <span className={classes.icon}>
                            <CircleIcon showConnectButton={props.showConnectButton} />
                        </span>
                        {props.showConnectButton ? 
                            <span>
                                <span className={classes.headerText1}>Connect</span>
                            </span> :
                            <span className={classes.headerText}>Connected</span> 
                        }
                    </button>
            }
        </React.Fragment>
    )
};

export default HeaderMetaMaskButton;