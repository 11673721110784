import React from 'react';

const circleIcon = (props) => {
    return (
        <React.Fragment>
            {props.showConnectButton ?
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="red" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"/>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="rgb(0,218,11)" className="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"/>
                </svg>
            }
        </React.Fragment>
    )
}

export default circleIcon;