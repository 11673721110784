import React from 'react';

import classes from './Header.module.css';
import DogPoundLogo from '../../assets/DogPoundLogo.png';
import HeaderMetaMaskButton from './HeaderMetaMaskButton';

const Header = props => {
    return <React.Fragment>
        <header className={classes.header}>
            <HeaderMetaMaskButton onConnectClick={props.onConnectMeta} onDisconnectClick={props.onDisconnectMeta} showConnectButton={props.showConnectButton}/>
        </header>
    </React.Fragment>
};

export default Header;