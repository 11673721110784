import Web3 from 'web3';

import classes from "./App.module.css";
import timelineClasses from "./appTimeline.module.css";
import teamClasses from "./appTeam.module.css";
import React, {Fragment} from "react";
import {web3, web3Mobile, providerMobile} from './web3';
import {
  isBrowser,
  isMobile
} from "react-device-detect";
import {dogPoundDesktop, dogPoundMobile} from './dogPound';

import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import DogPoundImage from './assets/DogPound1000.png';
import CheetahDog from './assets/CheetahDog.png';
import ScarredDog from './assets/ScarredDog.png';
import BlueZebraDog from './assets/BlueZebraDog.png';
import Description from './components/Description/Description';
import TwitterIcon from './components/Layout/TwitterIcon';
import Card from './components/Layout/Card';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin-top: 2rem;
  border-color: red;
`;


class App extends React.Component {

  state = {
    tokenURI: '',
    players: [],
    balance: '',
    value: '',
    mintedDogs: '',
    totalSupply: '',
    showConnectButton: true,
    loginError: false,
    maxLimit: false,
    saleOver: false,
    exceeds20: false,
    minted: false,
    paused: true,
    loading: false,
    soldOut: false,
    noMeta: true
  }


  async componentDidMount() {
    let check = false;

    try{

//for desktop
      if(isBrowser) {
        let ethereum = await window.ethereum;


        await web3.eth.getAccounts(function(err, accounts){
          if (accounts.length === 0) check = true;
          else check = false;
        });

        if (ethereum) {
          let that = this;
          await ethereum.on('accountsChanged', function (accounts) {
            if(accounts[0]) {
              that.removeUser(false);
            }
            else {
              that.removeUser(true);
            }
          });
        }

        const totalSupply = 8000;
        const mintedDogs = await dogPoundDesktop.methods.totalSupply().call();
        const paused = await dogPoundDesktop.methods.getPaused().call();


        if (mintedDogs >= totalSupply) {
          await this.setState({soldOut: true});
        }
        else {
          await this.setState({soldOut: false});
        }

        await this.setState({ showConnectButton: check, totalSupply, mintedDogs, paused, noMeta: check });
      }


//for mobile phones
      else if(isMobile) {
        await web3Mobile.eth.getAccounts(function(err, accounts){
          // if (err != null) console.error("An error occurred: "+err);
          if (accounts.length === 0) check = true;
          else check = false;
        });

        if(check === false) {
                    
          let that = this;
          await providerMobile.on("accountsChanged", (accounts) => {
            if(accounts[0]) {
              that.removeUser(false);
            }
            else {
              that.removeUser(true);
            }
          });
          
          const totalSupply = 8000;
          const mintedDogs = await dogPoundMobile.methods.totalSupply().call();
          const paused = await dogPoundMobile.methods.getPaused().call();

          if (mintedDogs >= totalSupply) {
            await this.setState({soldOut: true});
          }
          else {
            await this.setState({soldOut: false});
          }
          await this.setState({ showConnectButton: check, totalSupply, mintedDogs, paused, noMeta: check });
        }
      }

    }
    catch(err) {
      console.log(err);
    }
    
  }



  onSubmit = async (event) => {
    event.preventDefault();
    //for desktop
    if(isBrowser) {
      const accounts = await web3.eth.getAccounts();

      if(accounts[0] == null) {
        await this.setState({maxLimit: false,
                            saleOver: false,
                            exceeds20: false,
                            minted: false
                            })
        await this.setState({loginError: true})
        return;
      }

      if (this.state.value < 1 || this.state.value > 20) {
        await this.setState({loginError: false, 
                              maxLimit: false,
                              saleOver: false,
                              minted: false
                            })
        await this.setState({exceeds20: true});
        return;
      }

      if ((parseInt(this.state.mintedDogs) + parseInt(this.state.value)) >= parseInt(this.state.totalSupply)) {
        await this.setState({loginError: false, 
                              saleOver: false,
                              exceeds20: false,
                              minted: false
                            })
        await this.setState({maxLimit: true});
        return;
      }

      try {
        await this.setState({ loading: true });
        
        await dogPoundDesktop.methods.mintMyStuff(accounts[0], this.state.value).send({
          from: accounts[0],
          value: this.state.value * 55000000000000000 //0.055 eth
        });

        await this.setState({ loading: false });
        this.setState({ minted: true});
      }
      catch(error) {
        console.log('error', error.message);
        await this.setState({ loading: false });
      }
    }
    

    //for Mobile
    if(isMobile) {
        const accounts = await web3Mobile.eth.getAccounts();

        if(accounts[0] == null) {
          await this.setState({maxLimit: false,
                              saleOver: false,
                              exceeds20: false,
                              minted: false
                              })
          await this.setState({loginError: true})
          return;
        }

        if (this.state.value < 1 || this.state.value > 20) {
          await this.setState({loginError: false, 
                                maxLimit: false,
                                saleOver: false,
                                minted: false
                              })
          await this.setState({exceeds20: true});
          return;
        }

        if ((parseInt(this.state.mintedDogs) + parseInt(this.state.value)) >= parseInt(this.state.totalSupply)) {
          await this.setState({loginError: false, 
                                saleOver: false,
                                exceeds20: false,
                                minted: false
                              })
          await this.setState({maxLimit: true});
          return;
        }

        try {
          
          await dogPoundMobile.methods.mintMyStuff(accounts[0], this.state.value).send({
            from: accounts[0],
            value: this.state.value * 55000000000000000 //0.055 eth
          });

          this.setState({ minted: true});
        }
        catch(error) {
          console.log('error', error.message);
          await this.setState({ loading: false });
        }
      // }
    }
    
  };


  disconnectMeta = async (event) => {
    event.preventDefault();
    if(isMobile) {
      await providerMobile.disconnect();
      await this.setState({showConnectButton: true, noMeta: true});
    }
  }


  connectMeta = async (event) => {
    event.preventDefault();
    try {
      //for desktop
      if(isBrowser) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        if (accounts[0] == null) {
          throw new Error("Account not logged in");
        }
        this.setState({showConnectButton: false, loginError: false, noMeta: false});
      }

      //for Mobile
      if(isMobile) {
        //  Create WalletConnect Provider
        await providerMobile.enable();        

        const accounts = await web3Mobile.eth.getAccounts();

        if (accounts[0] == null) {
          throw new Error("Account not logged in");
        }

        await this.setState({showConnectButton: false, loginError: false, noMeta: false});
      }
    }
    catch(error) {
      console.log(error);
    }
  };


  removeUser = (check) => {
    this.setState({showConnectButton: check, noMeta: check});
  }






  render() {
    
    return (
      <Fragment>
        <div className={classes.background}>
          <Header onConnectMeta={this.connectMeta} onDisconnectMeta={this.disconnectMeta} showConnectButton={this.state.showConnectButton}/>
            <div className={classes.mainDiv}>
                <div className={classes.centeringDiv}>
                  <Description mintedDogs={this.state.mintedDogs} totalSupply={this.state.totalSupply}/>
                  
                  <form onSubmit={this.onSubmit}>
                    <div className={classes.group}>

                        {/* {this.state.loginError ? <label className={`${classes.formLabel} ${classes.formError}`}>Connect to MetaMask: </label> :
                          this.state.maxLimit ? <label className={`${classes.formLabel} ${classes.formError}`}>Mint &#62; Max Supply: </label> :
                            this.state.saleOver ? <label className={`${classes.formLabel} ${classes.formError}`}>Sale is over: </label> : 
                              this.state.exceeds20 ? <label className={`${classes.formLabel} ${classes.formError}`}>Max mint limit is 20: </label> : 
                                this.state.minted ? <label className={classes.formLabel}>Minted! </label> :
                                  <label className={classes.formLabel}>Join the pack: </label>} */}

                        <input
                            required
                            value={this.state.value}
                            placeholder='Mint #'
                            onChange={event => this.setState({ value: event.target.value })}
                        />
                        <div>
                          {this.state.loginError ? <p className={`${classes.formLabel} ${classes.formError}`}>Connect to MetaMask: </p> :
                            this.state.maxLimit ? <p className={`${classes.formLabel} ${classes.formError}`}>Mint &#62; Max Supply: </p> :
                              this.state.saleOver ? <p className={`${classes.formLabel} ${classes.formError}`}>Sale is over: </p> : 
                                this.state.exceeds20 ? <p className={`${classes.formLabel} ${classes.formError}`}>Max mint limit is 20: </p> : 
                                  this.state.minted ? <p className={classes.formLabel}>Minted! </p> :
                                    ''}
                        </div>
                        <div className={classes.buttonDiv}>
                          {this.state.noMeta ?
                            <p className={classes.mintingSoon}>Connect</p> :
                              // this.state.paused ? 
                              //   <p className={classes.mintingSoon}>Minting Soon</p> :
                                  this.state.soldOut ? 
                                  <p className={classes.mintingSoon}>Sold Out!</p> :
                                    this.state.loading ? 
                                    <BeatLoader css={override} color='red' loading={this.state.loading} size={17} /> : 
                                    <button className={`${classes.mintButton} ${classes.clickable}`}>Mint</button>
                          }
                        </div>
                    </div>
                  </form>
                
                </div>
              </div>
        </div>
        
      </Fragment>
    );
  }
}
export default App;
